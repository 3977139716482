import { default as competition_45privacy_45policy_45gwagonazwOBjb2ELMeta } from "/root/injective/injective-helix/pages/competition-privacy-policy-gwagon.vue?macro=true";
import { default as competition_45privacy_45policy_45teslaEe9OqLfAadMeta } from "/root/injective/injective-helix/pages/competition-privacy-policy-tesla.vue?macro=true";
import { default as envHhkoTKFmEfMeta } from "/root/injective/injective-helix/pages/env.vue?macro=true";
import { default as fee_45discountsyNiZGjmTdbMeta } from "/root/injective/injective-helix/pages/fee-discounts.vue?macro=true";
import { default as futuresovabMDJGIKMeta } from "/root/injective/injective-helix/pages/futures.vue?macro=true";
import { default as _91guild_932E0gVDfzCtMeta } from "/root/injective/injective-helix/pages/guild/[guild].vue?macro=true";
import { default as guildsUJO24TyvxSMeta } from "/root/injective/injective-helix/pages/guilds.vue?macro=true";
import { default as indexafr8iNXDu5Meta } from "/root/injective/injective-helix/pages/index.vue?macro=true";
import { default as institutional9Ei8pa9WDHMeta } from "/root/injective/injective-helix/pages/institutional.vue?macro=true";
import { default as competitionAVRkYE4lmhMeta } from "/root/injective/injective-helix/pages/leaderboard/competition.vue?macro=true";
import { default as indexKpRHODW8XeMeta } from "/root/injective/injective-helix/pages/leaderboard/index.vue?macro=true";
import { default as past_45competitionsczQ1fu5FaIMeta } from "/root/injective/injective-helix/pages/leaderboard/past-competitions.vue?macro=true";
import { default as leaderboard63CqDt6R5kMeta } from "/root/injective/injective-helix/pages/leaderboard.vue?macro=true";
import { default as like_45a_45g_45competition_45termsStxDn6EUIkMeta } from "/root/injective/injective-helix/pages/like-a-g-competition-terms.vue?macro=true";
import { default as campaignFRtPjquDPSMeta } from "/root/injective/injective-helix/pages/lp-rewards/campaign.vue?macro=true";
import { default as dashboardlBWrUSsPeeMeta } from "/root/injective/injective-helix/pages/lp-rewards/dashboard.vue?macro=true";
import { default as index86uHwg7cxaMeta } from "/root/injective/injective-helix/pages/lp-rewards/index.vue?macro=true";
import { default as lp_45rewardsZs7g30JjBRMeta } from "/root/injective/injective-helix/pages/lp-rewards.vue?macro=true";
import { default as maintenancejzbVwTGzdMMeta } from "/root/injective/injective-helix/pages/maintenance.vue?macro=true";
import { default as markets0qN8znMjD4Meta } from "/root/injective/injective-helix/pages/markets.vue?macro=true";
import { default as playgroundMICs7oinLbMeta } from "/root/injective/injective-helix/pages/playground.vue?macro=true";
import { default as pointsvb4B6jBZDdMeta } from "/root/injective/injective-helix/pages/points.vue?macro=true";
import { default as indexbbntxz0mKeMeta } from "/root/injective/injective-helix/pages/portfolio/balances/index.vue?macro=true";
import { default as indexsCNa1lNzKSMeta } from "/root/injective/injective-helix/pages/portfolio/history/funding-history/index.vue?macro=true";
import { default as indexboVUqmxGBgMeta } from "/root/injective/injective-helix/pages/portfolio/history/swap/index.vue?macro=true";
import { default as indexZcvJMr1SszMeta } from "/root/injective/injective-helix/pages/portfolio/history/wallet/index.vue?macro=true";
import { default as index5uqvSMXQVCMeta } from "/root/injective/injective-helix/pages/portfolio/index.vue?macro=true";
import { default as futures_45gridbRnmF1e6Y0Meta } from "/root/injective/injective-helix/pages/portfolio/orders/futures-grid.vue?macro=true";
import { default as advanced_45orderswPymH0TMSLMeta } from "/root/injective/injective-helix/pages/portfolio/orders/futures/advanced-orders.vue?macro=true";
import { default as indexw8dKlXAWQLMeta } from "/root/injective/injective-helix/pages/portfolio/orders/futures/index.vue?macro=true";
import { default as order_45historyJlFe2VYKd8Meta } from "/root/injective/injective-helix/pages/portfolio/orders/futures/order-history.vue?macro=true";
import { default as trade_45historymcDtu9ZgzIMeta } from "/root/injective/injective-helix/pages/portfolio/orders/futures/trade-history.vue?macro=true";
import { default as futuresjQZah79EbpMeta } from "/root/injective/injective-helix/pages/portfolio/orders/futures.vue?macro=true";
import { default as history7yWrS3e7LSMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot-grid/history.vue?macro=true";
import { default as indexonwAMWoTirMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot-grid/index.vue?macro=true";
import { default as spot_45gridYo9tecYuzqMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot-grid.vue?macro=true";
import { default as indexhFFKasXH6aMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot/index.vue?macro=true";
import { default as order_45historyrmpFwHgVrWMeta } from "/root/injective/injective-helix/pages/portfolio/orders/spot/order-history.vue?macro=true";
import { default as trade_45historyzwS9ePHeP2Meta } from "/root/injective/injective-helix/pages/portfolio/orders/spot/trade-history.vue?macro=true";
import { default as spotIrpY2ruzv1Meta } from "/root/injective/injective-helix/pages/portfolio/orders/spot.vue?macro=true";
import { default as indexNOFtGKlPEcMeta } from "/root/injective/injective-helix/pages/portfolio/positions/index.vue?macro=true";
import { default as granterDkd1GdhmzTMeta } from "/root/injective/injective-helix/pages/portfolio/settings/authz/granter.vue?macro=true";
import { default as indexovhNaqBomCMeta } from "/root/injective/injective-helix/pages/portfolio/settings/authz/index.vue?macro=true";
import { default as authzlYcJImBSKtMeta } from "/root/injective/injective-helix/pages/portfolio/settings/authz.vue?macro=true";
import { default as autosignGu9wEwX2Q3Meta } from "/root/injective/injective-helix/pages/portfolio/settings/autosign.vue?macro=true";
import { default as indexhZRGgAgF6ZMeta } from "/root/injective/injective-helix/pages/portfolio/settings/index.vue?macro=true";
import { default as preferencesyYHgUidP7SMeta } from "/root/injective/injective-helix/pages/portfolio/settings/preferences.vue?macro=true";
import { default as indexrTffH0fnuuMeta } from "/root/injective/injective-helix/pages/portfolio/subaccounts/index.vue?macro=true";
import { default as portfolio1iR5JDOmuXMeta } from "/root/injective/injective-helix/pages/portfolio.vue?macro=true";
import { default as spotW1MXLxNeOAMeta } from "/root/injective/injective-helix/pages/spot.vue?macro=true";
import { default as swapZVLi3jyaaaMeta } from "/root/injective/injective-helix/pages/swap.vue?macro=true";
import { default as termsiNnhQngnD9Meta } from "/root/injective/injective-helix/pages/terms.vue?macro=true";
import { default as tesla_45competition_45termsd5RSZiVSo0Meta } from "/root/injective/injective-helix/pages/tesla-competition-terms.vue?macro=true";
import { default as trade_45and_45earnE5uk6DptgWMeta } from "/root/injective/injective-helix/pages/trade-and-earn.vue?macro=true";
import { default as indexGbOaYOHCIbMeta } from "/root/injective/injective-helix/pages/trading-bots/index.vue?macro=true";
import { default as indexYmfueowi6tMeta } from "/root/injective/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue?macro=true";
import { default as transferDfNtk54FPcMeta } from "/root/injective/injective-helix/pages/transfer.vue?macro=true";
import { default as vaultstpT5cbONuJMeta } from "/root/injective/injective-helix/pages/vaults.vue?macro=true";
export default [
  {
    name: "competition-privacy-policy-gwagon",
    path: "/competition-privacy-policy-gwagon",
    component: () => import("/root/injective/injective-helix/pages/competition-privacy-policy-gwagon.vue")
  },
  {
    name: "competition-privacy-policy-tesla",
    path: "/competition-privacy-policy-tesla",
    component: () => import("/root/injective/injective-helix/pages/competition-privacy-policy-tesla.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: envHhkoTKFmEfMeta || {},
    component: () => import("/root/injective/injective-helix/pages/env.vue")
  },
  {
    name: "fee-discounts",
    path: "/fee-discounts",
    component: () => import("/root/injective/injective-helix/pages/fee-discounts.vue")
  },
  {
    name: "futures",
    path: "/futures",
    component: () => import("/root/injective/injective-helix/pages/futures.vue")
  },
  {
    name: "guild-guild",
    path: "/guild/:guild()",
    component: () => import("/root/injective/injective-helix/pages/guild/[guild].vue")
  },
  {
    name: "guilds",
    path: "/guilds",
    component: () => import("/root/injective/injective-helix/pages/guilds.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/root/injective/injective-helix/pages/index.vue")
  },
  {
    name: "institutional",
    path: "/institutional",
    meta: institutional9Ei8pa9WDHMeta || {},
    component: () => import("/root/injective/injective-helix/pages/institutional.vue")
  },
  {
    name: leaderboard63CqDt6R5kMeta?.name,
    path: "/leaderboard",
    component: () => import("/root/injective/injective-helix/pages/leaderboard.vue"),
    children: [
  {
    name: "leaderboard-competition",
    path: "competition",
    component: () => import("/root/injective/injective-helix/pages/leaderboard/competition.vue")
  },
  {
    name: "leaderboard",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/leaderboard/index.vue")
  },
  {
    name: "leaderboard-past-competitions",
    path: "past-competitions",
    component: () => import("/root/injective/injective-helix/pages/leaderboard/past-competitions.vue")
  }
]
  },
  {
    name: "like-a-g-competition-terms",
    path: "/like-a-g-competition-terms",
    component: () => import("/root/injective/injective-helix/pages/like-a-g-competition-terms.vue")
  },
  {
    name: lp_45rewardsZs7g30JjBRMeta?.name,
    path: "/lp-rewards",
    component: () => import("/root/injective/injective-helix/pages/lp-rewards.vue"),
    children: [
  {
    name: "lp-rewards-campaign",
    path: "campaign",
    component: () => import("/root/injective/injective-helix/pages/lp-rewards/campaign.vue")
  },
  {
    name: "lp-rewards-dashboard",
    path: "dashboard",
    component: () => import("/root/injective/injective-helix/pages/lp-rewards/dashboard.vue")
  },
  {
    name: "lp-rewards",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/lp-rewards/index.vue")
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenancejzbVwTGzdMMeta || {},
    component: () => import("/root/injective/injective-helix/pages/maintenance.vue")
  },
  {
    name: "markets",
    path: "/markets",
    component: () => import("/root/injective/injective-helix/pages/markets.vue")
  },
  {
    name: "playground",
    path: "/playground",
    meta: playgroundMICs7oinLbMeta || {},
    component: () => import("/root/injective/injective-helix/pages/playground.vue")
  },
  {
    name: "points",
    path: "/points",
    component: () => import("/root/injective/injective-helix/pages/points.vue")
  },
  {
    name: portfolio1iR5JDOmuXMeta?.name,
    path: "/portfolio",
    meta: portfolio1iR5JDOmuXMeta || {},
    component: () => import("/root/injective/injective-helix/pages/portfolio.vue"),
    children: [
  {
    name: "portfolio-balances",
    path: "balances",
    component: () => import("/root/injective/injective-helix/pages/portfolio/balances/index.vue")
  },
  {
    name: "portfolio-history-funding-history",
    path: "history/funding-history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/history/funding-history/index.vue")
  },
  {
    name: "portfolio-history-swap",
    path: "history/swap",
    component: () => import("/root/injective/injective-helix/pages/portfolio/history/swap/index.vue")
  },
  {
    name: "portfolio-history-wallet",
    path: "history/wallet",
    component: () => import("/root/injective/injective-helix/pages/portfolio/history/wallet/index.vue")
  },
  {
    name: "portfolio",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/index.vue")
  },
  {
    name: "portfolio-orders-futures-grid",
    path: "orders/futures-grid",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures-grid.vue")
  },
  {
    name: futuresjQZah79EbpMeta?.name,
    path: "orders/futures",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures.vue"),
    children: [
  {
    name: "portfolio-orders-futures-advanced-orders",
    path: "advanced-orders",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures/advanced-orders.vue")
  },
  {
    name: "portfolio-orders-futures",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures/index.vue")
  },
  {
    name: "portfolio-orders-futures-order-history",
    path: "order-history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures/order-history.vue")
  },
  {
    name: "portfolio-orders-futures-trade-history",
    path: "trade-history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/futures/trade-history.vue")
  }
]
  },
  {
    name: spot_45gridYo9tecYuzqMeta?.name,
    path: "orders/spot-grid",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot-grid.vue"),
    children: [
  {
    name: "portfolio-orders-spot-grid-history",
    path: "history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot-grid/history.vue")
  },
  {
    name: "portfolio-orders-spot-grid",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot-grid/index.vue")
  }
]
  },
  {
    name: spotIrpY2ruzv1Meta?.name,
    path: "orders/spot",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot.vue"),
    children: [
  {
    name: "portfolio-orders-spot",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot/index.vue")
  },
  {
    name: "portfolio-orders-spot-order-history",
    path: "order-history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot/order-history.vue")
  },
  {
    name: "portfolio-orders-spot-trade-history",
    path: "trade-history",
    component: () => import("/root/injective/injective-helix/pages/portfolio/orders/spot/trade-history.vue")
  }
]
  },
  {
    name: "portfolio-positions",
    path: "positions",
    component: () => import("/root/injective/injective-helix/pages/portfolio/positions/index.vue")
  },
  {
    name: authzlYcJImBSKtMeta?.name,
    path: "settings/authz",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/authz.vue"),
    children: [
  {
    name: "portfolio-settings-authz-granter",
    path: "granter",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/authz/granter.vue")
  },
  {
    name: "portfolio-settings-authz",
    path: "",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/authz/index.vue")
  }
]
  },
  {
    name: "portfolio-settings-autosign",
    path: "settings/autosign",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/autosign.vue")
  },
  {
    name: "portfolio-settings",
    path: "settings",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/index.vue")
  },
  {
    name: "portfolio-settings-preferences",
    path: "settings/preferences",
    component: () => import("/root/injective/injective-helix/pages/portfolio/settings/preferences.vue")
  },
  {
    name: "portfolio-subaccounts",
    path: "subaccounts",
    component: () => import("/root/injective/injective-helix/pages/portfolio/subaccounts/index.vue")
  }
]
  },
  {
    name: "spot",
    path: "/spot",
    component: () => import("/root/injective/injective-helix/pages/spot.vue")
  },
  {
    name: "swap",
    path: "/swap",
    meta: swapZVLi3jyaaaMeta || {},
    component: () => import("/root/injective/injective-helix/pages/swap.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/root/injective/injective-helix/pages/terms.vue")
  },
  {
    name: "tesla-competition-terms",
    path: "/tesla-competition-terms",
    component: () => import("/root/injective/injective-helix/pages/tesla-competition-terms.vue")
  },
  {
    name: "trade-and-earn",
    path: "/trade-and-earn",
    component: () => import("/root/injective/injective-helix/pages/trade-and-earn.vue")
  },
  {
    name: "trading-bots",
    path: "/trading-bots",
    component: () => import("/root/injective/injective-helix/pages/trading-bots/index.vue")
  },
  {
    name: "trading-bots-liquidity-bots-spot",
    path: "/trading-bots/liquidity-bots/spot",
    component: () => import("/root/injective/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue")
  },
  {
    name: "transfer",
    path: "/transfer",
    component: () => import("/root/injective/injective-helix/pages/transfer.vue")
  },
  {
    name: "vaults",
    path: "/vaults",
    component: () => import("/root/injective/injective-helix/pages/vaults.vue")
  },
  {
    name: "futures-slug",
    path: "/futures/:slug()",
    component: () => import("/root/injective/injective-helix/pages/futures.vue")
  },
  {
    name: "spot-slug",
    path: "/spot/:slug()",
    component: () => import("/root/injective/injective-helix/pages/spot.vue")
  }
]